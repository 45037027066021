export default {
  title: "المستخدمون",
  entityName: "user",
  properties: [
    {
      text: "#UID",
      value: "id",
      align: "right",
      field: "_id",
      sortable: false,
    },
    {
      text: "الاسم الأول",
      value: "firstName",
      align: "right",
      field: "_firstName",
      sortable: false,
    },
    {
      text: "الاسم الثاني",
      value: "lastName",
      align: "right",
      field: "_lastName",
      sortable: false,
    },
    {
      text: "البريد الإلكتروني",
      value: "email",
      align: "right",
      field: "_email",
      sortable: false,
    },
  ],
  getHeader() {
    let newArr = [];

    this.properties.forEach(({ text, value, align, sortable }) => {
      newArr.push({ text, value, align, sortable });
    });

    return newArr;
  },
  getSortDropdown() {
    let newArr = [];

    this.properties.forEach(({ text, field }) => {
      newArr.push({ text, field });
    });

    return newArr;
  },
};
