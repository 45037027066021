<template>
  <div class="d-flex justify-center mb-6">
    <div
      v-if="wholePageLoad"
      class="d-flex h-100 justify-center align-items-center"
      style="min-height:50vh;"
    >
      <v-progress-circular
        indeterminate
        color="black"
        size="50"
      ></v-progress-circular>
    </div>
    <v-card v-else elevation="0" style="width: 98%; max-width: 1000px;">
      <v-btn class="ma-2" outlined @click="goBack">
        <span style="font-size: 1.2rem" class="ml-2 mb-1">
          رجوع
        </span>
        <v-icon>mdi-backburger</v-icon>
      </v-btn>
      <div class="d-flex justify-center mb-6 mt-2">
        <v-img
          max-height="400"
          style="object-fit: cover; width: 100%;"
          :src="apiUrl + '/entities/images/raw/' + articleImage + '/' + '900'"
        />
      </div>

      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="fields.title"
          label="العنوان"
          required
          class="mb-5"
        ></v-text-field>

        <v-text-field
          v-model="fields.page"
          label="الصفحة"
          required
          type="number"
          class="mb-5"
        ></v-text-field>

        <v-text-field
          v-model="fields.subtitle"
          label="العنوان الفرعي"
          required
          class="mb-5"
        ></v-text-field>

        <v-text-field
          v-model="fields.medium"
          label="الموضوع"
          required
          class="mb-5"
        ></v-text-field>

        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="التاريخ"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="saveDate()">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>

        <v-textarea required v-model="fields.content" class="mb-5">
          <template v-slot:label>
            <div class="pb-2"> نص
          المقالة</div>
          </template>
        </v-textarea>

        <v-text-field
          v-model="fields.edition"
          label="رقم العدد"
          type="number"
          required
        ></v-text-field>

        <!-- DYNAMIC FIELDS -->

        <div class="mb-6">
          <v-autocomplete
            v-model="selectedAuthor"
            :items="authors"
            chips
            :loading="isLoading"
            :search-input.sync="searchAuthor"
            clearable
            hide-details
            hide-selected
            item-text="name"
            item-value="id"
            label="المؤلف"
            class="mb-5 pb-5"
            no-data-text="لا يوجد عناصر للعرض"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>

        <div class="mb-6">
          <v-autocomplete
            v-model="selectedTranslator"
            :items="translators"
            chips
            :loading="isLoading"
            :search-input.sync="searchTranslator"
            clearable
            hide-details
            hide-selected
            item-text="name"
            item-value="id"
            label="المترجم"
            class="mb-5 pb-5"
            no-data-text="لا يوجد عناصر للعرض"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>

        <div class="mb-6">
          <v-autocomplete
            v-model="selectedLanguage"
            chips
            :items="languages"
            :loading="isLoading"
            :search-input.sync="searchLanguage"
            clearable
            hide-details
            hide-selected
            item-text="name"
            item-value="id"
            label="اللغة"
            class="mb-5 pb-5"
            no-data-text="لا يوجد عناصر للعرض"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>

        <div class="mb-6">
          <v-autocomplete
            v-model="selectedSource"
            chips
            :items="sources"
            :loading="isLoading"
            :search-input.sync="searchSource"
            clearable
            hide-details
            hide-selected
            item-text="name"
            item-value="id"
            label="المصدر"
            class="mb-5 pb-5"
            no-data-text="لا يوجد عناصر للعرض"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>

        <div class="mb-6">
          <v-autocomplete
            v-model="selectedCategory"
            chips
            :items="categories"
            :loading="isLoading"
            :search-input.sync="searchCategory"
            clearable
            hide-details
            hide-selected
            item-text="category"
            item-value="id"
            label="إصدار خاص "
            class="mb-5 pb-5"
            no-data-text="لا يوجد عناصر للعرض"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.category"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>

        <div class="mb-6">
          <v-autocomplete
            v-model="selectedTopic"
            chips
            :items="topics"
            :loading="isLoading"
            :search-input.sync="searchTopic"
            clearable
            hide-details
            hide-selected
            item-text="topic"
            item-value="id"
            label="الموضوع"
            class="mb-5 pb-5"
            no-data-text="لا يوجد عناصر للعرض"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.topic"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>

        <div class="mb-6">
          <v-autocomplete
            v-model="selectedType"
            chips
            :items="types"
            :loading="isLoading"
            :search-input.sync="searchType"
            clearable
            hide-details
            hide-selected
            item-text="type"
            item-value="id"
            label="  نوع المقالة"
            class="mb-5 pb-5"
            no-data-text="لا يوجد عناصر للعرض"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.type"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>

        <h2 class="mb-5 text-center">التسميات المرتبطة</h2>

        <v-data-iterator
          :items-per-page="-1"
          :items="groupedItems"
          hide-default-footer
        >
          <template v-slot:default="props">
            <v-row>
              <v-col
                v-for="entity in props.items"
                :key="entity[0]"
                cols="12"
                sm="6"
                md="4"
                lg="4"
              >
                <v-card class="mx-auto mr-2 mt-2" outlined>
                  <v-card-text v-text="entity[0]" />
                  <v-list-item
                    v-for="row in entity[1]"
                    :key="row.entityId"
                    two-line
                    class="d-flex justify-end align-end"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="text-align-right">{{
                        row.name
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        row.type
                      }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-icon
                      @click="deleteEntityBlock(row.entityId, $route.params.id)"
                      style="cursor: pointer;"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                  <add-new-entity-search
                    :group-id="entity[0]"
                    :is-loading="isLoading"
                    @selected="addEntityToArticleBlock"
                    @toggleloading="toggleLoading"
                  />
                </v-card>
              </v-col>
              <v-col>
                <v-card
                  style="min-height: 195.6px;"
                  class="mx-auto mr-2 mt-2 d-flex justify-center align-center"
                  outlined
                >
                  <div v-if="showNew">
                    <v-icon
                      style="font-size: 6rem; cursor: pointer;"
                      x-large
                      @click="setNewAddInterface"
                      >add</v-icon
                    >
                  </div>
                  <div v-else>
                    <v-list-item
                      v-for="row in emptyBlock.selectedEntities"
                      :key="row.entityId"
                      two-line
                      class="d-flex justify-end align-end"
                    >
                      <v-list-item-content>
                        <v-list-item-title class="text-align-right">{{
                          row.name
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          row.type
                        }}</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-icon
                        @click="deleteEntityEmptyBlock(row.entityId)"
                        style="cursor: pointer;"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                    <v-autocomplete
                      v-model="emptyBlock.newEntityId"
                      :items="emptyBlock.entitiesSearch"
                      :loading="isLoading"
                      :search-input.sync="searchEntityNewBlock"
                      clearable
                      flat
                      solo-inverted
                      hide-details
                      hide-selected
                      item-text="name"
                      item-value="id"
                      prepend-inner-icon="add"
                      class="mb-5 pr-3 pl-3"
                      no-data-text="لا يوجد عناصر للعرض"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title
                            @click="addToNewEmptyBlock(item.id)"
                            v-text="item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                    <div class="d-flex justify-center mb-2">
                      <v-btn
                        @click="saveNewEmptyBlocks"
                        depressed
                        outlined
                        large
                      >
                        <span style="font-size: 1.2rem" class="ml-2 mt-1">
                          حفظ
                        </span>
                        <v-icon>save</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </template>
          <template v-slot:no-data>
            <div class="d-flex flex-row justify-center mt-4">
              لا يوجد عناصر للعرض
            </div>
          </template>
        </v-data-iterator>

        <h2 class="mt-7 text-center">الصور المرتبطة</h2>

        <v-data-iterator
          :items-per-page="-1"
          :items="images"
          hide-default-footer
        >
          <template v-slot:header>
            <v-toolbar class="mb-1" elevation="0">
              <v-autocomplete
                v-model="newImageId"
                :items="imagesSearch"
                :loading="isLoading"
                :search-input.sync="searchImage"
                clearable
                flat
                solo-inverted
                hide-details
                hide-selected
                no-filter
                item-text="fileName"
                item-value="id"
                prepend-inner-icon="mdi-magnify"
                class="mb-5 mt-5"
                no-data-text="لا يوجد عناصر للعرض"
              >
                <template v-slot:append-item>
                  <div class="d-flex justify-center">
                    <v-btn
                      v-if="!initialSearch"
                      :loading="isLoading"
                      @click="loadMoreImages"
                      class="white--text"
                      color="#607D8B"
                      elevation="0"
                      >المزيد</v-btn
                    >
                  </div>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content
                    style="max-width: 930px;"
                    @click="addImageToArticleBlock(item.id)"
                  >
                    <v-list-item-title
                      v-text="item.caption"
                    ></v-list-item-title>
                    <div class="image-image-container mt-2">
                      <img
                        class="h-10 image-image"
                        :src="
                          apiUrl +
                            '/entities/images/raw/' +
                            item.fileName +
                            '/' +
                            200
                        "
                      />
                    </div>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-toolbar>
          </template>
          <template v-slot:no-data>
            <div class="d-flex flex-row justify-center mt-4">
              لا يوجد عناصر للعرض
            </div>
          </template>
          <template v-slot:default="props">
            <v-row>
              <v-col
                v-for="image in props.items"
                :key="image.id"
                cols="12"
                sm="6"
                md="3"
                lg="3"
              >
                <v-card class="mx-auto mr-2 mt-2" outlined>
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-overline mb-4">
                        Image
                      </div>
                      <div class="image-image-container">
                        <img
                          style="max-height: 127px;"
                          class="h-20 image-image"
                          :src="
                            apiUrl +
                              '/entities/images/raw/' +
                              image.fileName +
                              '/' +
                              200
                          "
                        />
                      </div>
                      <v-list-item-title class="text-h8 mb-2 mt-2">
                        {{ image.description ? image.description : "-" }}
                      </v-list-item-title>
                      <v-list-item-subtitle>{{
                        image.fileName
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-card-actions>
                    <v-btn
                      @click="deleteImageBlock(image.id, $route.params.id)"
                      rounded
                      depressed
                    >
                      حذف
                    </v-btn>
                    <v-btn
                      @click="
                        $router.push({
                          name: 'ImageEdit',
                          params: { id: image.id },
                        })
                      "
                      rounded
                      depressed
                    >
                      تعديل
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>

        <v-btn
          :disabled="!valid"
          class="mr-4 mt-5"
          @click="deleteAll"
          depressed
          outlined
          large
        >
          <span style="font-size: 1.2rem" class="ml-2 mt-1">
            حذف المقالة وجميع العلاقات
          </span>
          <v-icon>cancel</v-icon>
        </v-btn>

        <v-btn class="mr-4 mt-5" @click="goBack" depressed outlined large>
          <span style="font-size: 1.2rem" class="ml-2 mt-1">
            إلغاء
          </span>
          <v-icon>backspace</v-icon>
        </v-btn>

        <v-btn
          :disabled="!valid"
          class="mr-4 mt-5"
          @click="submit"
          depressed
          outlined
          large
        >
          <span style="font-size: 1.2rem" class="ml-2 mt-1">
            حفظ
          </span>
          <v-icon>save</v-icon>
        </v-btn>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { searchAuthors } from "@services/Authors.api";
import { deleteArticle } from "@services/ArticlesBackend.api";
import config from "@/helpers/config";
import { searchLanguages } from "@services/Languages.api";
import { searchSources } from "@services/Sources.api";
import { searchCategories } from "@services/Categories.api";
import { searchTopics } from "@services/Topic.api";
import { searchTypes } from "@services/Types.api";
import { searchEntities, getEntityById } from "@services/Entities.api";
import { simpleSearchImages, getImageById } from "@services/ImagesBackend.api";
import {
  deleteEntityfromArticleBlock,
  deleteImagefromArticleBlock,
  addEntityArticleBlock,
  addImageArticleBlock,
  getHighestArticleBlock,
} from "@services/Custom.api";
import groupByProperty from "@helpers/groupByProperty";
import AddNewEntitySearch from "./AddNewEntitySearch";

export default {
  components: { AddNewEntitySearch },
  data: () => ({
    fields: {
      authorId: 0,
      translatorId: 0,
      languageId: 0,
      sourceId: 0,
      categoryId: 0,
      topicId: 0,
      typeId: 0,
      title: "",
      page: 0,
      subtitle: "",
      medium: "",
      edition: 0,
      content: "",
    },
    date: null,
    menu: null,
    valid: true,
    isLoading: false,

    authors: [],
    languages: [],
    sources: [],
    categories: [],
    topics: [],
    types: [],
    translators: [],

    selectedAuthor: null,
    selectedTranslator: null,
    selectedLanguage: null,
    selectedSource: null,
    selectedCategory: null,
    selectedTopic: null,
    selectedType: null,

    newImageId: 0,

    entities: [],
    images: [],
    imagesSearch: [],

    searchAuthor: null,
    searchTranslator: null,
    searchLanguage: null,
    searchSource: null,
    searchCategory: null,
    searchTopic: null,
    searchType: null,
    searchImage: null,

    articleImage: null,

    wholePageLoad: true,

    emptyBlock: {
      newBlockId: 0,
      newEntityId: 0,
      entitiesSearch: [],
      selectedEntities: [],
    },
    searchEntityNewBlock: null,

    query: {
      order: "ASC",
      sortBy: "_id",
      pageSize: 5,
      pageNumber: 1,
    },
    searchCounter: 5,
    initialSearch: true,
    apiUrl: config.apiUrl,

    showNew: true,
    newBlockId: null,
  }),

  computed: {
    groupedItems: function() {
      return Object.entries(groupByProperty(this.entities, "blockId"));
    },
  },

  methods: {
    ...mapActions("articlesBackend", ["getArticle", "updateArticle"]),
    ...mapMutations("notification", { notifyVuex: "notify" }),
    addEntityToArticleBlock(entityId, blockId) {
      addEntityArticleBlock(
        JSON.stringify({ entityId, blockId, articleId: this.$route.params.id })
      )
        .then(() => {
          getEntityById(entityId).then((res) => {
            let { id, name, type } = res.data.payload;
            this.entities = [
              ...this.entities,
              { entityId: id, name, type, blockId },
            ];
          });
        })
        .catch(() => {
          this.notifyVuex({
            show: true,
            message: "يجب أن تكون الكيانات فريدة داخل كتلة المقالة",
          });
        });
    },
    addImageToArticleBlock(imageId) {
      addImageArticleBlock(
        JSON.stringify({ entityId: imageId, articleId: this.$route.params.id })
      )
        .then(() => {
          getImageById(imageId).then((res) => {
            let { id, name, type, fileName } = res.data.payload;
            this.images = [...this.images, { id, name, type, fileName }];
            this.searchImage = "";
          });
        })
        .catch(() => {
          this.searchImage = "";
        });
    },
    addToNewEmptyBlock(entityId) {
      getEntityById(entityId)
        .then((res) => {
          let { id, name, type } = res.data.payload;
          this.emptyBlock.selectedEntities = [
            ...this.emptyBlock.selectedEntities,
            { entityId: id, name, type },
          ];
          this.searchEntityNewBlock = "";
        })
        .catch(() => {
          this.searchEntityNewBlock = "";
        });
    },
    deleteEntityEmptyBlock(entityId) {
      this.confirmDialog(
        "هل أنت متأكد(ة)؟ لا يمكن التراجع عن هذه الخطوة",
        () => {
          this.emptyBlock.selectedEntities = this.emptyBlock.selectedEntities.filter(
            (el) => el.entityId !== entityId
          );
        }
      );
    },
    saveNewEmptyBlocks() {
      this.isLoading = true;
      let done = false;
      let newBlockId = this.emptyBlock.newBlockId;
      this.emptyBlock.selectedEntities.forEach(
        ({ entityId, type, name }, index, arr) => {
          if (index === arr.length - 1) {
            done = true;
          }
          addEntityArticleBlock(
            JSON.stringify({
              entityId,
              blockId: this.emptyBlock.newBlockId,
              articleId: this.$route.params.id,
            })
          ).then(() => {
            this.entities = [
              ...this.entities,
              { entityId, type, name, blockId: newBlockId },
            ];

            if (done) {
              this.emptyBlock = {
                newBlockId: 0,
                newEntityId: 0,
                entitiesSearch: [],
                searchEntityNewBlock: null,
                selectedEntities: [],
              };
              this.isLoading = false;
              this.showNew = true;
            }
          });
        }
      );
    },
    setNewAddInterface() {
      this.showNew = !this.showNew;
      getHighestArticleBlock().then((res) => {
        this.emptyBlock.newBlockId = res.data.payload;
      });
    },
    submit() {
      const { id } = this.$route.params;

      try {
        this.fields.authorId = this.selectedAuthor.id
          ? this.selectedAuthor.id
          : this.selectedAuthor;

        this.fields.translatorId = this.selectedTranslator.id
          ? this.selectedTranslator.id
          : this.selectedTranslator;

        this.fields.languageId = this.selectedLanguage.id
          ? this.selectedLanguage.id
          : this.selectedLanguage;

        this.fields.sourceId = this.selectedSource.id
          ? this.selectedSource.id
          : this.selectedSource;

        this.fields.categoryId = this.selectedCategory.id
          ? this.selectedCategory.id
          : this.selectedCategory;

        this.fields.topicId = this.selectedTopic.id
          ? this.selectedTopic.id
          : this.selectedTopic;

        this.fields.typeId = this.selectedType.id
          ? this.selectedType.id
          : this.selectedType;

        const dateArr = this.date.split("-");
        const dateObjects = {
          year: dateArr[0],
          month: dateArr[1],
          day: dateArr[2],
          date: null,
        };
        dateObjects.date =
          dateObjects.year +
          "-" +
          this.formattedMonth(dateObjects.month) +
          "-" +
          dateObjects.day;

        this.$confirm({
          message: `هل أنت متأكد(ة)؟ لا يمكن التراجع عن هذه الخطوة`,
          button: {
            no: "لا",
            yes: "نعم",
          },
          callback: (confirm) => {
            if (confirm) {
              this.wholePageLoad = true;

              this.updateArticle({ id, ...this.fields, ...dateObjects }).then(
                () => {
                  this.wholePageLoad = false;

                  // this.$router.push({name: "BackendArticle"});
                }
              );
            }
          },
        });
      } catch {
        this.notifyVuex({
          show: true,
          message: "يجب ألا تكون جميع الحقول المطلوبة فارغة",
        });
      }
    },
    goBack() {
      this.$router.push({ name: "BackendArticle" });
    },
    toggleLoading(state) {
      this.isLoading = state;
    },
    formattedMonth(month) {
      let newMonth = month + "";
      if (newMonth.length === 1) {
        newMonth = "0" + month;
        return newMonth;
      }
      return month;
    },
    deleteEntityBlock(entityId, articleId) {
      this.confirmDialog(
        "هل أنت متأكد(ة)؟ لا يمكن التراجع عن هذه الخطوة",
        () => {
          deleteEntityfromArticleBlock(entityId, articleId)
            .then(() => {
              this.entities = this.entities.filter(
                (el) => el.entityId !== entityId
              );
            })
            .catch((err) => {
              console.log(err);
            });
        }
      );
    },
    deleteImageBlock(imageId, articleId) {
      this.confirmDialog(
        "هل أنت متأكد(ة)؟ لا يمكن التراجع عن هذه الخطوة",
        () => {
          deleteImagefromArticleBlock(imageId, articleId)
            .then(() => {
              this.images = this.images.filter((el) => el.id !== imageId);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      );
    },
    saveDate() {
      this.$refs.menu.save(this.date);
    },
    deleteAll() {
      this.$confirm({
        message: `هل أنت متأكد(ة)؟ لا يمكن التراجع عن هذه الخطوة`,
        button: {
          no: "لا",
          yes: "نعم",
        },
        callback: (confirm) => {
          if (confirm) {
            this.wholePageLoad = true;
            const { id } = this.$route.params;

            deleteArticle(id).then(() => {
              this.wholePageLoad = false;
              this.$router.push({ name: "BackendArticle" });
            });
          }
        },
      });
    },
    loadMoreImages() {
      this.searchCounter += 20;
      let val = this.searchImage;

      this.isLoading = true;

      simpleSearchImages(val, { ...this.query, pageSize: this.searchCounter })
        .then((res) => {
          if (res.data.payload.length === this.imagesSearch.length) {
            this.notifyVuex({ show: true, message: "لا يوجد المزيد من الصور" });
          }
          this.imagesSearch = res.data.payload;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        })
        .finally(() => (this.isLoading = false));
    },
    confirmDialog(message, func) {
      this.$confirm({
        message,
        button: {
          no: "لا",
          yes: "نعم",
        },
        callback: (confirm) => {
          if (confirm) {
            func();
          }
        },
      });
    },
  },
  mounted() {
    const { id } = this.$route.params;

    this.getArticle(id).then(
      ({
        authorId,
        translatorId,
        translatorName,
        authorName,
        title,
        page,
        subtitle,
        medium,
        content,
        languageId,
        languageName,
        sourceId,
        sourceName,
        edition,
        categoryId,
        categoryName,
        topicId,
        topicName,
        typeId,
        typeName,
        entities,
        images,
        newPdfPath,
        oldPdfPath,
        day,
        month,
        year,
      }) => {
        this.articleImage =
          newPdfPath && newPdfPath.length > 0 ? newPdfPath : oldPdfPath;

        this.selectedAuthor = { id: authorId, name: authorName };
        this.authors.push({ id: authorId, name: authorName });

        this.selectedTranslator = { id: translatorId, name: translatorName };
        this.translators.push({ id: translatorId, name: translatorName });

        this.selectedLanguage = { id: languageId, name: languageName };
        this.languages.push({ id: languageId, name: languageName });

        this.selectedSource = { id: sourceId, name: sourceName };
        this.sources.push({ id: sourceId, name: sourceName });

        this.selectedCategory = { id: categoryId, category: categoryName };
        this.categories.push({ id: categoryId, category: categoryName });

        this.selectedType = { id: typeId, type: typeName };
        this.types.push({ id: typeId, type: typeName });

        this.selectedTopic = { id: topicId, topic: topicName };
        this.topics.push({ id: topicId, topic: topicName });

        this.fields.title = title;
        this.fields.page = page;
        this.fields.subtitle = subtitle;
        this.fields.medium = medium;
        this.fields.edition = edition;
        this.fields.content = content;

        this.date = year + "-" + month + "-" + day;

        this.entities = entities;
        this.images = images;

        this.wholePageLoad = false;
      }
    );
  },
  watch: {
    searchAuthor(val) {
      if (!val) return;

      this.isLoading = true;

      searchAuthors(val, this.query)
        .then((res) => {
          this.authors = res.data.payload;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    searchTranslator(val) {
      if (!val) return;

      this.isLoading = true;

      searchAuthors(val, this.query)
        .then((res) => {
          this.translators = res.data.payload;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    searchLanguage(val) {
      if (!val) return;

      this.isLoading = true;

      searchLanguages(val, this.query)
        .then((res) => {
          this.languages = res.data.payload;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    searchSource(val) {
      if (!val) return;

      this.isLoading = true;

      searchSources(val, this.query)
        .then((res) => {
          this.sources = res.data.payload;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    searchCategory(val) {
      if (!val) return;

      this.isLoading = true;

      searchCategories(val, this.query)
        .then((res) => {
          this.categories = res.data.payload;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    searchTopic(val) {
      if (!val) return;

      this.isLoading = true;

      searchTopics(val, this.query)
        .then((res) => {
          this.topics = res.data.payload;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    searchType(val) {
      if (!val) return;

      this.isLoading = true;

      searchTypes(val, this.query)
        .then((res) => {
          this.types = res.data.payload;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    searchImage(val) {
      if (!val) return;
      this.initialSearch = false;

      this.isLoading = true;

      simpleSearchImages(val, this.query)
        .then((res) => {
          this.imagesSearch = res.data.payload;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        })
        .finally(() => (this.isLoading = false));
    },
    searchEntityNewBlock(val) {
      if (!val) return;

      this.isLoading = true;

      searchEntities(val, this.query)
        .then((res) => {
          this.emptyBlock.entitiesSearch = res.data.payload;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
