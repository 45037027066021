function groupByProperty(arr, criteria) {
  const newObj = arr.reduce(function(acc, currentValue) {
    if (!acc[currentValue[criteria]]) {
      acc[currentValue[criteria]] = [];
    }
    acc[currentValue[criteria]].push(currentValue);
    return acc;
  }, {});
  return newObj;
}

export default groupByProperty;
