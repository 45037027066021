import { httpClientBackend as service } from "./Service";

const pushChangesToElastic = () => {
  return service.post(
    "/flush-elastic"
  );
};

const deleteEntityfromArticleBlock = (entityId, articleId) => {
  return service.delete(
    "/entities/articles/blocks/article/" + articleId + "/entity/" + entityId
  );
};

const deleteImagefromArticleBlock = (imageId, articleId) => {
  return service.delete(
    "/entities/articles/blocks/article/" + articleId + "/image/" + imageId
  );
};

const addEntityArticleBlock = (data) => {
  return service.post("/entities/articles/blocks", data);
};

const addImageArticleBlock = (data) => {
  return service.post("/entities/articles/images", data);
};

const deleteEntityfromImageBlock = (entityId, imageId) => {
  return service.delete(
    "/entities/images/blocks/image/" + imageId + "/entity/" + entityId
  );
};

const addEntityImageBlock = (data) => {
  return service.post("/entities/images/blocks", data);
};

const getHighestArticleBlock = () => {
  return service.get("/entities/articles/newBlock");
};

const deleteImagefromUserBlock = (imageId, userId) => {
  return service.delete(
    "/entities/users/blocks/user/" + userId + "/image/" + imageId
  );
};

const addImageUserBlock = (data) => {
  return service.post("/entities/users/blocks/image", data);
};

const deleteArticlefromUserBlock = (articleId, userId) => {
  return service.delete(
    "/entities/users/blocks/user/" + userId + "/article/" + articleId
  );
};

const addArticleUserBlock = (data) => {
  return service.post("/entities/users/blocks/article", data);
};

export {
  pushChangesToElastic,
  deleteEntityfromArticleBlock,
  addEntityArticleBlock,
  deleteEntityfromImageBlock,
  deleteImagefromArticleBlock,
  addEntityImageBlock,
  addImageArticleBlock,
  getHighestArticleBlock,
  deleteImagefromUserBlock,
  addImageUserBlock,
  deleteArticlefromUserBlock,
  addArticleUserBlock
};
