<template>
  <div class="d-flex justify-center mb-6">
    <div
      v-if="wholePageLoad"
      class="d-flex h-100 justify-center align-items-center"
      style="min-height:50vh;"
    >
      <v-progress-circular
        indeterminate
        color="black"
        size="50"
      ></v-progress-circular>
    </div>
    <v-card v-else elevation="0" style="width: 98%; max-width: 1000px;">
      <v-btn class="ma-2" outlined @click="goBack">
        <span style="font-size: 1.2rem" class="ml-2 mb-1">
          رجوع
        </span>
        <v-icon>mdi-backburger</v-icon>
      </v-btn>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="fields.firstName"
          label="الاسم الأول"
          required
        ></v-text-field>

        <v-text-field
          v-model="fields.lastName"
          label="الاسم الثاني"
          required
        ></v-text-field>

        <v-text-field
          v-model="fields.email"
          label="البريد الإلكتروني"
          required
        ></v-text-field>

        <v-container>
          <v-row>
            <v-col cols="12" md="4">
              <v-checkbox v-model="fields.activated">
                <template v-slot:label>
                  <span class="mt-2" id="checkboxLabel1">مفعل</span>
                </template>
              </v-checkbox>
            </v-col>

            <v-col cols="12" md="4">
              <v-checkbox v-model="fields.backend">
                <template v-slot:label>
                  <span class="mt-2" id="checkboxLabel2">مشرف</span>
                </template>
              </v-checkbox>
            </v-col>

            <v-col cols="12" md="4">
              <v-checkbox v-model="fields.enabled">
                <template v-slot:label>
                  <span class="mt-2" id="checkboxLabel3">ممكن</span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">
              <v-checkbox v-model="fields.deleted">
                <template v-slot:label>
                  <span class="mt-2" id="checkboxLabel4">تم الحذف</span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="12" md="4">
              <v-checkbox v-model="properties.allowAllAccess">
                <template v-slot:label>
                  <span class="mt-2" id="checkboxLabel5">دخول كامل</span>
                </template></v-checkbox
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                label="تاريخ الإنشاء"
                v-model="uneditableFields.dateCreated"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="التاريخ عدل"
                v-model="uneditableFields.dateModified"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-textarea v-model="uneditableFields.properties" disabled>
          <template v-slot:label>
            <div>الخصائص</div>
          </template>
        </v-textarea>

        <h2 class="mb-5 text-center">الصور المرتبطة</h2>

        <v-data-iterator
          :items-per-page="-1"
          :items="images"
          hide-default-footer
        >
          <template v-slot:header>
            <v-toolbar class="mb-1" elevation="0">
              <v-autocomplete
                v-model="newImageId"
                :items="imagesSearch"
                :loading="isLoading"
                :search-input.sync="searchImage"
                clearable
                flat
                solo-inverted
                hide-details
                hide-selected
                no-filter
                item-text="fileName"
                item-value="id"
                prepend-inner-icon="mdi-magnify"
                class="mb-5 mt-5"
                no-data-text="لا يوجد عناصر للعرض"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content
                    style="max-width: 930px;"
                    @click="addImageToUserBlock(item.id)"
                  >
                    <v-list-item-title
                      v-text="item.caption"
                    ></v-list-item-title>
                    <div class="image-image-container mt-2">
                      <img
                        class="h-10 image-image"
                        :src="
                          apiUrl +
                            '/entities/images/raw/' +
                            item.fileName +
                            '/' +
                            200
                        "
                      />
                    </div>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-toolbar>
          </template>
          <template v-slot:no-data>
            <div class="d-flex flex-row justify-center mt-4">
              لا يوجد عناصر للعرض
            </div>
          </template>
          <template v-slot:default="props">
            <v-row>
              <v-col
                v-for="image in props.items"
                :key="image.id"
                cols="12"
                sm="6"
                md="3"
                lg="3"
              >
                <v-card class="mx-auto mr-2 mt-2" outlined>
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-overline mb-4">
                        صورة
                      </div>
                      <div class="image-image-container">
                        <img
                          style="max-height: 127px;"
                          class="h-20 image-image"
                          :src="
                            apiUrl +
                              '/entities/images/raw/' +
                              image.fileName +
                              '/' +
                              200
                          "
                        />
                      </div>
                      <v-list-item-title class="text-h8 mb-2 mt-2">
                        {{ image.description ? image.description : "-" }}
                      </v-list-item-title>
                      <v-list-item-subtitle>{{
                        image.fileName
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-card-actions>
                    <v-btn
                      @click="deleteImageBlock(image.id, $route.params.id)"
                      rounded
                      depressed
                    >
                      حذف
                    </v-btn>
                    <v-btn
                      @click="
                        $router.push({
                          name: 'ImageEdit',
                          params: { id: image.id },
                        })
                      "
                      rounded
                      depressed
                    >
                      تعديل
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>

        <h2 class="mb-5 mt-5 text-center">المقالات المرتبطة</h2>

        <v-data-iterator
          :items-per-page="-1"
          :items="articles"
          hide-default-footer
        >
          <template v-slot:header>
            <v-toolbar class="mb-1" elevation="0">
              <v-autocomplete
                v-model="newArticleId"
                :items="articlesSearch"
                :loading="isLoading"
                :search-input.sync="searchArticle"
                clearable
                flat
                solo-inverted
                hide-details
                hide-selected
                no-filter
                item-text="title"
                item-value="id"
                prepend-inner-icon="mdi-magnify"
                class="mb-5 mt-5"
                no-data-text="لا يوجد عناصر للعرض"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content
                    style="max-width: 930px;"
                    @click="addArticleToUserBlock(item.id)"
                  >
                    <v-list-item-title
                      v-text="item.title"
                    ></v-list-item-title>
                    <div class="image-image-container mt-2">
                      <img
                        class="h-10 image-image"
                        :src="
                          apiUrl +
                            '/entities/images/raw/' +
                            (item.newPdfPath && item.newPdfPath.length > 0
                            ? item.newPdfPath
                            : item.oldPdfPath) +
                          '/' +
                            200
                        "
                      />
                    </div>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-toolbar>
          </template>
          <template v-slot:no-data>
            <div class="d-flex flex-row justify-center mt-4">
              لا يوجد عناصر للعرض
            </div>
          </template>
          <template v-slot:default="props">
            <v-row>
              <v-col
                v-for="article in props.items"
                :key="article.id"
                cols="12"
                sm="6"
                md="3"
                lg="3"
              >
                <v-card class="mx-auto mr-2 mt-2" outlined>
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-overline mb-4">
                        مقالة
                      </div>
                      <div class="image-image-container">
                        <img
                          style="max-height: 127px; object-fit: cover; min-width: 100%;"
                          class="h-20"
                          :src="
                            apiUrl +
                            '/entities/images/raw/' +
                            (article.newPdfPath && article.newPdfPath.length > 0
                            ? article.newPdfPath
                            : article.oldPdfPath) +
                          '/' +
                            800
                          "
                        />
                      </div>
                      <v-list-item-title class="text-h8 mb-2 mt-2">
                        {{ article.title ? article.title : "-" }}
                      </v-list-item-title>
                      <v-list-item-subtitle>{{
                        article.date
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-card-actions>
                    <v-btn
                      @click="deleteArticleBlock(article.id, $route.params.id)"
                      rounded
                      depressed
                    >
                      حذف
                    </v-btn>
                    <v-btn
                      @click="
                        $router.push({
                          name: 'ArticleEdit',
                          params: { id: article.id },
                        })
                      "
                      rounded
                      depressed
                    >
                      تعديل
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>


        <v-btn class="mr-4 mt-8" @click="goBack" depressed outlined large>
          <span style="font-size: 1.2rem" class="ml-2 mt-1">
            إلغاء
          </span>
          <v-icon>cancel</v-icon>
        </v-btn>

        <v-btn
          :disabled="!valid"
          class="mr-4 mt-8"
          @click="submit"
          depressed
          outlined
          large
        >
          <span style="font-size: 1.2rem" class="ml-2 mt-1">
            حفظ
          </span>
          <v-icon>save</v-icon>
        </v-btn>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import config from "@/helpers/config";
import { simpleSearchImages, getImageById } from "@services/ImagesBackend.api";
import { searchArticles, getArticleById } from "@services/ArticlesBackend.api";
import {
  deleteImagefromUserBlock,
  addImageUserBlock,
  deleteArticlefromUserBlock,
  addArticleUserBlock
} from "@services/Custom.api";

export default {
  data: () => ({
    valid: true,
    wholePageLoad: true,
    uneditableFields: { dateCreated: "", dateModified: "", properties: "" },
    fields: {
      firstName: "",
      lastName: "",
      email: "",
      activated: false,
      backend: false,
      enabled: false,
      deleted: false,
    },
    properties: {
      allowAllAccess: false,
    },

    images: [],
    newImageId: 0,
    imagesSearch: [],
    searchImage: null,

    articles: [],
    newArticleId: 0,
    articlesSearch: [],
    searchArticle: null,

    query: {
      order: "ASC",
      sortBy: "_id",
      pageSize: 5,
      pageNumber: 1,
    },
    isLoading: false,
    apiUrl: config.apiUrl,



  }),

  methods: {
    ...mapActions("users", ["getUserByIdWithData", "updateUser"]),
    submit() {
      const { id } = this.$route.params;

      this.$confirm({
        message: `هل أنت متأكد(ة)؟ لا يمكن التراجع عن هذه الخطوة`,
        button: {
          no: "لا",
          yes: "نعم",
        },
        callback: (confirm) => {
          if (confirm) {
            this.wholePageLoad = true;

            let properties = {
              allowAllAccess: this.properties.allowAllAccess,
            };
            this.updateUser({ id, ...this.fields, properties }).then(() => {
              // this.$router.push({ name: "BackendUser" });

              this.wholePageLoad = false;
            });
          }
        },
      });
    },
    goBack() {
      this.$router.push({ name: "BackendUser" });
    },
    confirmDialog(message, func) {
      this.$confirm({
        message,
        button: {
          no: "لا",
          yes: "نعم",
        },
        callback: (confirm) => {
          if (confirm) {
            func();
          }
        },
      });
    },
    deleteImageBlock(imageId, userId) {
      this.confirmDialog(
          "هل أنت متأكد(ة)؟ لا يمكن التراجع عن هذه الخطوة",
          () => {
            deleteImagefromUserBlock(imageId, userId)
                .then(() => {
                  this.images = this.images.filter((el) => el.id !== imageId);
                })
                .catch((err) => {
                  console.log(err);
                });
          }
      );
    },
    addImageToUserBlock(imageId) {
      addImageUserBlock(
          JSON.stringify({ imageId: imageId, userId: this.$route.params.id })
      )
          .then(() => {
            getImageById(imageId).then((res) => {
              let { id, name, type, fileName } = res.data.payload;
              this.images = [...this.images, { id, name, type, fileName }];
              this.searchImage = "";
            });
          })
          .catch(() => {
            this.searchImage = "";
          });
    },
    deleteArticleBlock(articleId, userId) {
      this.confirmDialog(
          "هل أنت متأكد(ة)؟ لا يمكن التراجع عن هذه الخطوة",
          () => {
            deleteArticlefromUserBlock(articleId, userId)
                .then(() => {
                  this.articles = this.articles.filter((el) => el.id !== articleId);
                })
                .catch((err) => {
                  console.log(err);
                });
          }
      );
    },
    addArticleToUserBlock(articleId) {
      addArticleUserBlock(
          JSON.stringify({ articleId, userId: this.$route.params.id })
      )
          .then(() => {
            getArticleById(articleId).then((res) => {
              let { id, newPdfPath, oldPdfPath, title, date } = res.data.payload;
              this.articles = [...this.articles, { id, newPdfPath, oldPdfPath, title, date }];
              this.searchArticle = "";
            });
          })
          .catch(() => {
            this.searchArticle = "";
          });
    },
  },
  mounted() {
    const { id } = this.$route.params;
    this.getUserByIdWithData(id).then(
      ({
          images,
          articles,
         user: {
           firstName,
           lastName,
           email,
           activated,
           enabled,
           backend,
           deleted,
           dateCreated,
           dateModified,
           properties,
         },
       }) => {
        this.fields.firstName = firstName;
        this.fields.lastName = lastName;
        this.fields.email = email;
        this.fields.activated = activated;
        this.fields.backend = backend;
        this.fields.enabled = enabled;
        this.fields.deleted = deleted;

        this.images = images;
        this.articles = articles;

        if (properties) {
          this.properties.allowAllAccess = properties.allowAllAccess || false;
        }

        this.uneditableFields.properties = JSON.stringify({ ...properties });
        this.uneditableFields.dateCreated = new Date(
          dateCreated
        ).toLocaleDateString("en-US");
        this.uneditableFields.dateModified = new Date(
          dateModified
        ).toLocaleDateString("en-US");

        this.wholePageLoad = false;
      }
    );
  },
  watch: {
    searchImage(val) {
      if (!val) return;

      this.isLoading = true;

      simpleSearchImages(val, this.query)
          .then((res) => {
            this.imagesSearch = res.data.payload;
          })
          .catch((err) => {
            console.log(err);
            this.isLoading = false;
          })
          .finally(() => (this.isLoading = false));
    },
    searchArticle(val) {
      if (!val) return;

      this.isLoading = true;

      searchArticles(val, this.query)
          .then((res) => {
            this.articlesSearch = res.data.payload;
          })
          .catch(() => {
            this.isLoading = false;
          })
          .finally(() => (this.isLoading = false));
    },
  }
};
</script>
