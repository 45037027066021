export default {
    title: "إحصائيات المستخدمین",
    entityName: "userRequest",
    properties: [
      {
        text: "#UID",
        value: "id",
        align: "right",
        field: "_id",
        sortable: false,
      },
      {
        text: "الاسم الأول",
        value: "firstName",
        align: "right",
        field: "_firstName",
        sortable: false,
      },
      {
        text: "الاسم الثاني",
        value: "lastName",
        align: "right",
        field: "_lastName",
        sortable: false,
      },
      {
        text: "البريد الإلكتروني",
        value: "email",
        align: "right",
        field: "_email",
        sortable: false,
      },
      {
        text: "التاريخ",
        value: "date",
        align: "right",
        field: "_date",
        sortable: false,
      },
      {
        text: "عمليات البحث",
        value: "queryFlat",
        align: "right",
        field: "_queryFlat",
        sortable: false,
        restrictSort: true
      },
      {
        text: "عناصر البحث",
        value: "paramsFlat",
        align: "right",
        field: "_paramsFlat",
        sortable: false,
        restrictSort: true
      }
    ],
    getHeader() {
      let newArr = [];
  
      this.properties.forEach(({ text, value, align, sortable }) => {
        newArr.push({ text, value, align, sortable });
      });
  
      return newArr;
    },
    getSortDropdown() {
      let newArr = [];
  
      this.properties.forEach(({ text, field, restrictSort }) => {
        if (!restrictSort) {
          newArr.push({ text, field });
        }
      });
  
      return newArr;
    },
  };
  