<template>
  <div>
    <div class="chart-display">
      <div class="chart-container">
        <div
          class="chart w-100 h-100 d-flex justify-content-center align-items-center"
        >
          <wordcloud
            style="width: 100%; height: 100%"
            :data="wordCloudData.words"
            nameKey="name"
            valueKey="value"
            :color="wordCloudData.colors"
            :showTooltip="false"
            :font-size="[30, 80]"
            :wordPadding="13"
          >
          </wordcloud>
        </div>
      </div>
    </div>
    <div class="d-flex justify-center mb-6">
      <v-card elevation="0" style="width: 98%">
        <v-data-table
          v-model="selected"
          hide-default-footer
          :headers="headers"
          :items="getSearchResultUserRequests"
          :loading="loading"
          :items-per-page="query.pageSize"
          loading-text="Loading... Please wait"
          :footer-props="{
            itemsPerPageText: 'صفوف لكل صفحة',
            pageText: ` من ` + '1000' + `  صفحات`,
            itemsPerPageOptions: [20, 50, 75],
          }"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <!-- <v-text-field
                style="width: 100%; max-width: 450px"
                v-model="searchTerm"
                append-icon="search"
                label=""
                :disabled="loading"
                single-line
                clearable
                hide-details
                @click:clear="viewAll"
                @click:append="() => onChange(searchTerm)"
                @change="(term) => onChange(term)"
                ref="searchUserRequest"
              ></v-text-field> -->

              <v-spacer></v-spacer>
              <v-select
                :disabled="loading"
                @input="updateSortAndOrder"
                style="height: 40px; min-height: 44px; max-width: 105px"
                dense
                label="الترتيب حسب"
                v-model="sortByDropdown"
                :items="sortDropdown"
                filled
                class="ml-4"
                hide-selected
                return-object
                outlined
              ></v-select>
              <v-select
                :disabled="loading"
                @input="updateSortAndOrder"
                style="height: 40px; min-height: 44px; max-width: 105px"
                dense
                class="ml-4"
                label="التسلسل"
                v-model="orderDropdown"
                :items="[
                  { text: 'تصاعدي', field: 'ASC' },
                  { text: 'تنازلي', field: 'DESC' },
                ]"
                filled
                hide-selected
                return-object
                outlined
              ></v-select>
            </v-toolbar>
          </template>
        </v-data-table>
        <div class="d-flex justify-content-center w-100 mt-4">
          <v-pagination
            v-model="currentPage"
            color="#f29100ff"
            :length="totalPages"
            :total-visible="5"
            @input="handlePageChange"
          >
          </v-pagination>
          <v-select
            :items="[20, 50, 75]"
            v-model="query.pageSize"
            dense
            flat
            :loading="loading"
            label="عدد"
            class="mr-2"
            @change="updateSortAndOrder"
            style="max-width: 55px; max-height: 50px"
          ></v-select>
        </div>
        <div v-if="!loading" class="d-flex justify-content-center mt-4">
          {{ currentPageCountStart }} -
          {{ finalNumberText }}
          من أصل {{ getUserRequestsGlobalCount }}
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import wordcloud from "vue-wordcloud";
import { getUserRequestsWordCloud } from "@services/UserRequests.api";
import { mapActions, mapGetters, mapMutations } from "vuex";
import userRequestConfig from "@helpers/userRequestStructure";
import getSortableField from "@helpers/getSortableField";

export default {
  components: {
    wordcloud,
  },

  data() {
    return {
      wordCloudData: {
        id: "word-cloud-2",
        content: "",
        colors: ["#1f77b4", "#629fc9", "#94bedb", "#c9e0ef"],
        words: [],
      },

      title: userRequestConfig.title,
      singleSelect: false,
      searchTerm: "",
      selected: [],
      loading: false,
      sortByDropdown: { text: "#UID", field: "_id" },
      orderDropdown: { text: "تنازلي", field: "DESC" },
      headers: [
        ...userRequestConfig.getHeader(),
        { text: "", value: "actions", sortable: false },
      ],
      sortDropdown: userRequestConfig.getSortDropdown(),
      query: {
        order: "DESC",
        sortBy: "id",
        pageSize: 20,
        pageNumber: 1,
      },
      currentPageCountStart: 1,
      currentPageCountEnd: 1,
      currentPage: 1,
      totalPages: 0,
    };
  },

  methods: {
    ...mapActions("userRequests", [
      "fetchSearchResultUserRequests",
      "fetchAllUserRequests",
    ]),
    ...mapMutations("notification", { notifyVuex: "notify" }),
    ...mapMutations("userRequests", ["SET_NUMBER_OF_ROWS"]),
    setLoading(status) {
      this.loading = status;
      this.updatePagination();
    },
    viewAll() {
      this.setQuery();
      this.fetchAllUserRequests({
        query: this.query,
        loadingFunction: this.setLoading,
      });
      this.setLoading(true);
      this.searchTerm = "";
    },
    notify(message) {
      this.notifyVuex({ show: true, message });
    },
    updatePagination() {
      this.totalPages = Math.ceil(
        this.getUserRequestsGlobalCount / this.query.pageSize
      );
      this.currentPageCountStart =
        parseInt(this.query.pageSize) * parseInt(this.query.pageNumber) -
        parseInt(this.query.pageSize) +
        1;
      this.currentPageCountEnd =
        parseInt(this.query.pageSize) * parseInt(this.query.pageNumber) -
        parseInt(this.query.pageSize) +
        parseInt(this.query.pageSize);
    },
    search(term) {
      this.setQuery();
      if (term) {
        this.fetchSearchResultUserRequests({
          term,
          query: { ...this.query },
          loadingFunction: this.setLoading,
        });
      } else {
        this.viewAll();
      }
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.query.pageNumber = value;

      this.updateSortAndOrder();
    },
    onChange(term) {
      this.search(term);
      this.$refs.searchUserRequests.blur();
    },
    setQuery() {
      this.query.sortBy = getSortableField(this.sortByDropdown.field);
      this.query.order = this.orderDropdown.field;
    },
    updateSortAndOrder() {
      this.SET_NUMBER_OF_ROWS(this.query.pageSize);
      if (this.getSearchTermUserRequests) {
        this.search(this.searchTerm);
      } else {
        this.viewAll();
      }
    },
    updateWordCloud() {
      getUserRequestsWordCloud(500).then((res) => {
        let words = [];
        let response = res.data.payload;

        for (const property in response) {
          words.push({ name: property, value: parseInt(response[property]) });
        }

        this.wordCloudData.words = words;
      });
    },
    resizeEventHandler() {
      this.updateWordCloud();
    },
  },
  computed: {
    ...mapGetters("userRequests", [
      "getSearchResultUserRequests",
      "getSearchTermUserRequest",
      "getUserRequestsGlobalCount",
      "getUserRequestsLocalCount",
    ]),
    finalNumberText: function () {
      if (this.currentPageCountEnd > this.getUserRequestsGlobalCount) {
        return this.getUserRequestsGlobalCount;
      } else {
        return this.currentPageCountEnd;
      }
    },
  },

  created() {
    window.addEventListener("resize", this.resizeEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeEventHandler);
  },

  mounted() {
    this.updateWordCloud();

    this.currentPage = 1;
    this.query.pageSize = 20;
    if (!this.getSearchTermUserRequest) {
      this.viewAll();
    }
    this.searchTerm = this.getSearchTermUserRequest;
  },
};
</script>

<style lang="scss">
.chart-display {
  border-radius: 10px;
  width: 100%;
  height: 80vh;
  color: $black;

  @include md-and-above {
    max-height: 380px;
  }

  .chart-container {
    height: 100%;
  }
}
</style>
