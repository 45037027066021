<template>
  <div class="d-flex justify-center mb-6">
    <div
      v-if="wholePageLoad"
      class="d-flex h-100 justify-center align-items-center"
      style="min-height:50vh;"
    >
      <v-progress-circular
        indeterminate
        color="black"
        size="50"
      ></v-progress-circular>
    </div>
    <v-card v-else elevation="0" style="width: 98%; max-width: 1000px;">
      <v-btn class="ma-2" outlined @click="goBack">
        <span style="font-size: 1.2rem" class="ml-2 mb-1">
          رجوع
        </span>
        <v-icon>mdi-backburger</v-icon>
      </v-btn>
      <div class="d-flex justify-center mb-6 mt-2">
        <v-img
          max-height="400"
          style="object-fit: cover; width: 100%;"
          :src="apiUrl + '/entities/images/raw/' + fileName + '/' + '900'"
        />
      </div>
      <v-form ref="form" lazy-validation>
        <v-text-field
          v-model="fields.edition"
          label="رقم العدد"
          required
          type="number"
          class="mb-5"
        ></v-text-field>

        <v-text-field
          v-model="fields.section"
          label="الجزء"
          required
          class="mb-5"
        ></v-text-field>

        <v-text-field
          v-model="fields.caption"
          label="وصف الصورة"
          required
          class="mb-5"
        ></v-text-field>
        <v-text-field
          v-model="fields.description"
          label="شرح"
          required
          class="mb-5"
        ></v-text-field>
        <v-text-field
          v-model="fields.classification"
          label="التبويب"
          required
          class="mb-5"
        ></v-text-field>

        <v-text-field
          v-model="fields.type"
          label="نوع المقالات"
          required
          class="mb-5"
        ></v-text-field>

        <div class="mb-6">
          <v-autocomplete
            v-model="selectedSource"
            chips
            :items="sources"
            :loading="isLoading"
            :search-input.sync="searchSource"
            clearable
            hide-details
            hide-selected
            item-text="name"
            item-value="id"
            label="المصدر"
            class="mb-5 pb-5"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>

        <div class="mb-6">
          <v-autocomplete
            v-model="selectedPhotographer"
            chips
            :items="photographers"
            :loading="isLoading"
            :search-input.sync="searchPhotographer"
            clearable
            hide-details
            hide-selected
            item-text="name"
            item-value="id"
            label="المصور"
            class="mb-5 pb-5"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>

        <h2 class="mb-5 text-center">التسميات المرتبطة</h2>

        <v-data-iterator
          :items="entities"
          hide-default-footer
          :items-per-page="-1"
        >
          <template v-slot:header>
            <v-toolbar class="mb-1" elevation="0">
              <v-autocomplete
                v-model="newEntityId"
                :items="entitiesSearch"
                :loading="isLoading"
                :search-input.sync="searchEntity"
                clearable
                flat
                solo-inverted
                hide-details
                hide-selected
                item-text="name"
                item-value="id"
                prepend-inner-icon="mdi-magnify"
                class="mb-5 mt-5"
                no-data-text="لا يوجد عناصر للعرض"
              >
                <template v-slot:append-item>
                  <div class="d-flex justify-center">
                    <v-btn
                      v-if="!initialSearch"
                      :loading="isLoading"
                      @click="loadMoreEntities"
                      class="white--text"
                      color="#607D8B"
                      elevation="0"
                      >المزيد</v-btn
                    >
                  </div>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title
                      @click="addEntityToImageBlock(item.id)"
                      v-text="item.name"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-toolbar>
          </template>
          <template v-slot:default="props">
            <v-row>
              <v-col
                v-for="entity in props.items"
                :key="entity.id"
                cols="12"
                sm="6"
                md="3"
                lg="3"
              >
                <v-card class="mx-auto mr-2 mt-2" outlined>
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-title class="text-h8 mb-1 text-right">
                        {{ entity.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-right">{{
                        entity.type
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-card-actions>
                    <v-btn
                      @click="deleteEntityBlock(entity.id, $route.params.id)"
                      rounded
                      depressed
                    >
                      حذف
                    </v-btn>
                    <v-btn
                      @click="
                        $router.push({
                          name: 'EntityEdit',
                          params: { id: entity.id },
                        })
                      "
                      rounded
                      depressed
                    >
                      تعديل
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>
          <template v-slot:no-data>
            <div class="d-flex flex-row justify-center mt-4">
              لا يوجد عناصر للعرض
            </div>
          </template>
        </v-data-iterator>
        <v-btn class="mr-4 mt-5" @click="deleteAll" depressed outlined large>
          <span style="font-size: 1.2rem" class="ml-2 mt-1">
            حذف الصورة وجميع العلاقات
          </span>
          <v-icon>cancel</v-icon>
        </v-btn>

        <v-btn class="mr-4 mt-5" @click="goBack" depressed outlined large>
          <span style="font-size: 1.2rem" class="ml-2 mt-1">
            إلغاء
          </span>
          <v-icon>backspace</v-icon>
        </v-btn>

        <v-btn class="mr-4 mt-5" @click="submit" depressed outlined large>
          <span style="font-size: 1.2rem" class="ml-2 mt-1">
            حفظ
          </span>
          <v-icon>save</v-icon>
        </v-btn>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import config from "@/helpers/config";
import { searchSources } from "@services/Sources.api";
import { deleteImage } from "@services/ImagesBackend.api";
import { searchPhotographers } from "@services/Photographers.api";
import { searchEntities, getEntityById } from "@services/Entities.api";
import {
  deleteEntityfromImageBlock,
  addEntityImageBlock,
} from "@services/Custom.api";

export default {
  data: () => ({
    fields: {
      sourceId: 0,
      photographerId: 0,

      edition: 0,
      section: "",
      caption: "",
      description: "",
      classification: "",
      type: "",
    },
    isLoading: false,
    apiUrl: config.apiUrl,

    newEntityId: 0,

    entities: [],
    entitiesSearch: [],

    sources: [],
    photographers: [],

    selectedSource: null,
    selectedPhotographer: null,

    searchEntity: null,
    searchSource: null,
    searchPhotographer: null,

    fileName: "",

    wholePageLoad: true,
    emptyBlockName: "",
    emptyBlockType: "",

    query: {
      order: "ASC",
      sortBy: "_id",
      pageSize: 10,
      pageNumber: 1,
    },
    searchCounter: 5,
    initialSearch: true,
  }),

  methods: {
    ...mapActions("imagesBackend", ["getImage", "updateImage"]),
    ...mapMutations("notification", { notifyVuex: "notify" }),
    addEntityToImageBlock(entityId) {
      addEntityImageBlock(
        JSON.stringify({ entityId, imageId: this.$route.params.id })
      )
        .then(() => {
          getEntityById(entityId).then((res) => {
            let { id, name, type } = res.data.payload;
            this.entities = [...this.entities, { id, name, type }];
            this.searchEntity = "";
          });
        })
        .catch(() => {
          this.searchEntity = "";
        });
    },
    submit() {
      const { id } = this.$route.params;

      try {
        this.fields.sourceId = this.selectedSource.id
          ? this.selectedSource.id
          : this.selectedSource;

        this.fields.photographerId = this.selectedPhotographer.id
          ? this.selectedPhotographer.id
          : this.selectedPhotographer;

        this.$confirm({
          message: `هل أنت متأكد(ة)؟ لا يمكن التراجع عن هذه الخطوة`,
          button: {
            no: "لا",
            yes: "نعم",
          },
          callback: (confirm) => {
            if (confirm) {
              this.wholePageLoad = true;

              this.updateImage({ id, ...this.fields }).then(() => {
                this.wholePageLoad = false;

                // this.$router.push({name: "BackendImage"});
              });
            }
          },
        });
      } catch {
        this.notifyVuex({
          show: true,
          message: "يجب ألا تكون جميع الحقول المطلوبة فارغة",
        });
      }
    },
    goBack() {
      this.$router.push({ name: "BackendImage" });
    },
    deleteEntityBlock(entityId, imageId) {
      this.confirmDialog(
        "هل أنت متأكد(ة)؟ لا يمكن التراجع عن هذه الخطوة",
        () => {
          deleteEntityfromImageBlock(entityId, imageId)
            .then(() => {
              this.entities = this.entities.filter((el) => el.id !== entityId);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      );
    },
    confirmDialog(message, func) {
      this.$confirm({
        message,
        button: {
          no: "لا",
          yes: "نعم",
        },
        callback: (confirm) => {
          if (confirm) {
            func();
          }
        },
      });
    },
    deleteAll() {
      this.$confirm({
        message: `هل أنت متأكد(ة)؟ لا يمكن التراجع عن هذه الخطوة`,
        button: {
          no: "لا",
          yes: "نعم",
        },
        callback: (confirm) => {
          if (confirm) {
            this.wholePageLoad = true;
            const { id } = this.$route.params;

            deleteImage(id).then(() => {
              this.wholePageLoad = false;
              this.$router.push({ name: "BackendImage" });
            });
          }
        },
      });
    },
    loadMoreEntities() {
      this.searchCounter += 20;
      let val = this.searchEntity;

      this.isLoading = true;

      searchEntities(val, { ...this.query, pageSize: this.searchCounter })
        .then((res) => {
          if (res.data.payload.length === this.entitiesSearch.length) {
            this.notifyVuex({
              show: true,
              message: "لا يوجد المزيد من الكيانات",
            });
          }
          this.entitiesSearch = res.data.payload;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        })
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    const { id } = this.$route.params;

    this.getImage(id).then(
      ({
        sourceId,
        sourceName,
        edition,
        photographerId,
        photographerName,
        section,
        caption,
        description,
        classification,
        type,
        entities,
        fileName,
      }) => {
        this.fileName = fileName;
        this.selectedSource = { id: sourceId, name: sourceName };
        this.sources.push({ id: sourceId, name: sourceName });

        this.selectedPhotographer = {
          id: photographerId,
          name: photographerName,
        };
        this.photographers.push({ id: photographerId, name: photographerName });

        this.fields.edition = edition;
        this.fields.section = section;
        this.fields.caption = caption;
        this.fields.description = description;
        this.fields.classification = classification;
        this.fields.type = type;

        this.entities = entities;

        this.wholePageLoad = false;
      }
    );
  },
  watch: {
    searchSource(val) {
      if (!val) return;

      this.isLoading = true;

      searchSources(val, this.query)
        .then((res) => {
          this.sources = res.data.payload;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    searchPhotographer(val) {
      if (!val) return;

      this.isLoading = true;

      searchPhotographers(val, this.query)
        .then((res) => {
          this.photographers = res.data.payload;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    searchEntity(val) {
      if (!val) return;

      this.isLoading = true;
      this.initialSearch = false;

      searchEntities(val, this.query)
        .then((res) => {
          this.entitiesSearch = res.data.payload;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
