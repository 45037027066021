<template>
  <v-autocomplete
      v-model="entityId"
      :items="entitiesSearch"
      :loading="isLoading"
      :search-input.sync="searchEntity"
      @change="searchEntity=''"
      clearable
      flat
      solo-inverted
      hide-details
      hide-selected
      item-text="name"
      item-value="id"
      prepend-inner-icon="add"
      class="mb-5 pr-3 pl-3"
      no-data-text="لا يوجد عناصر للعرض"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title
            @click="selectEntity(item.id)"
            v-text="item.name"
        ></v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import {searchEntities} from "../../../../services/Entities.api";

export default {
  name: "AddNewEntitySearch",
  props: ['groupId', 'isLoading'],
  data() {
    return {
      entitiesSearch: [],
      searchEntity: null,
      entityId: 0,

      query: {
        order: "ASC",
        sortBy: "_id",
        pageSize: 5,
        pageNumber: 1,
      },
    }
  },
  methods: {
    selectEntity(id) {
      this.$emit("selected", id, this.groupId);
    }
  },
  watch: {
    searchEntity(val) {
      if (!val) return;

      this.$emit("toggleloading", true);

      searchEntities(val, this.query)
          .then((res) => {
            this.entitiesSearch = res.data.payload;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => (this.$emit("toggleloading", false)
          ));
    },
  },
}
</script>